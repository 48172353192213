import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useLocalStorage } from "usehooks-ts";
import SelectQuizCard from "../components/selectQuizCard/selectQuizCard";
import { useStats } from "../hooks/useStats";
import baseQuiz from "../quiz/base-quiz.json";
import sailQuiz from "../quiz/sail-quiz.json";

const Home: React.FC = () => {
  const [baseQuestionsFromLS] = useLocalStorage(
    "baseQuestions",
    JSON.stringify(baseQuiz?.questions)
  );
  const [sailQuestionsFromLS] = useLocalStorage(
    "sailQuestions",
    JSON.stringify(sailQuiz?.questions)
  );

  const {
    isStatisticPresent,
    numOfErrorsBase,
    numOfErrorsSail,
    numOfAnsweredBase,
    numOfAnsweredSail,
  } = useStats();

  return (
    <Container style={{ marginTop: "80px", maxWidth: "1024px" }}>
      <SelectQuizCard
        title="Quiz Motore"
        description={
          <Container>
            {isStatisticPresent && (
              <div style={{ marginBottom: "1em" }}>
                <ProgressBar>
                  <ProgressBar
                    variant="success"
                    animated
                    now={
                      (100 * (numOfAnsweredBase - numOfErrorsBase)) /
                      JSON.parse(baseQuestionsFromLS)?.length
                    }
                  />
                  <ProgressBar
                    animated
                    variant="danger"
                    now={
                      (100 * numOfErrorsBase) /
                      JSON.parse(baseQuestionsFromLS)?.length
                    }
                  />
                </ProgressBar>
              </div>
            )}
            <Row>
              <Col xs={12} sm={6} style={{ textAlign: "left" }}>
                <p>
                  <b>Tempo disponibile:</b> 30 minuti
                </p>
                <p>
                  <b>Quiz totali:</b> 20
                </p>
                <p>
                  <b>Errori concessi:</b> 4
                </p>
              </Col>
              <Col xs="auto" style={{ textAlign: "left" }}>
                {isStatisticPresent && (
                  <>
                    <p>
                      <b>Domande già svolte: </b>
                      {numOfAnsweredBase} su{" "}
                      {JSON.parse(baseQuestionsFromLS)?.length}
                    </p>
                    <p>
                      <b>Risposte corrette: </b>
                      {numOfAnsweredBase - numOfErrorsBase}
                    </p>
                    <p>
                      <b>Numero di errori: </b>
                      {numOfErrorsBase}
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        }
        linkList={[
          { url: "/base/simulation", linkDescription: "Esame completo" },
          {
            url: "/base/simulation-partial",
            linkDescription: "Esame parziale",
            variant: "outline-primary",
          },
          {
            url: "/base/training",
            linkDescription: "Argomenti",
            variant: "outline-warning",
          },
          {
            url: "/base/errors",
            linkDescription: "Errori",
            variant: "outline-danger",
          },
        ]}
      />
      <SelectQuizCard
        title="Quiz Vela"
        description={
          <Container>
            {isStatisticPresent && (
              <div style={{ marginBottom: "1em" }}>
                <ProgressBar>
                  <ProgressBar
                    variant="success"
                    animated
                    now={
                      (100 * (numOfAnsweredSail - numOfErrorsSail)) /
                      JSON.parse(sailQuestionsFromLS)?.length
                    }
                  />
                  <ProgressBar
                    animated
                    variant="danger"
                    now={
                      (100 * numOfErrorsSail) /
                      JSON.parse(sailQuestionsFromLS)?.length
                    }
                  />
                </ProgressBar>
              </div>
            )}
            <Row>
              <Col xs={12} sm={6} style={{ textAlign: "left" }}>
                <p>
                  <b>Tempo disponibile:</b> 15 minuti
                </p>
                <p>
                  <b>Quiz totali:</b> 5
                </p>
                <p>
                  <b>Errori concessi:</b> 1
                </p>
              </Col>
              <Col xs="auto" style={{ textAlign: "left" }}>
                {isStatisticPresent && (
                  <>
                    <p>
                      <b>Domande già svolte: </b>
                      {numOfAnsweredSail} su{" "}
                      {JSON.parse(sailQuestionsFromLS)?.length}
                    </p>
                    <p>
                      <b>Risposte corrette: </b>
                      {numOfAnsweredSail - numOfErrorsSail}
                    </p>
                    <p>
                      <b>Numero di errori: </b>
                      {numOfErrorsSail}
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        }
        linkList={[
          { url: "/sail/simulation", linkDescription: "Esame completo" },
          {
            url: "/sail/simulation-partial",
            linkDescription: "Esame parziale",
            variant: "outline-primary",
          },
          {
            url: "/sail/training",
            linkDescription: "Argomenti",
            variant: "outline-warning",
          },
          {
            url: "/sail/errors",
            linkDescription: "Errori",
            variant: "outline-danger",
          },
        ]}
      />
    </Container>
  );
};

export default Home;
