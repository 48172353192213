import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import chartQuiz from "../../quiz/chart-quiz.json";
import { useNavigate } from "react-router-dom";

export enum SectorsEnum {
  NW_H = "SETTORE NORD OVEST ORIZZONTALE",
  NW_V = "SETTORE NORD OVEST VERTICALE",
  SE = "SETTORE SUD EST",
}

const getSectorKey = (sector: string): string => {
  if (sector === SectorsEnum.NW_H) {
    return "/charts/quiz/sector_1";
  } else if (sector === SectorsEnum.NW_V) {
    return "/charts/quiz/sector_2";
  } else {
    return "/charts/quiz/sector_3";
  }
};

const ChartsQuiz: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <h1>Quiz Carteggio</h1>
      <h5>Settori</h5>
      <Row>
        {chartQuiz.sectors.map((sector, i) => {
          return (
            <Col
              key={i}
              xs={12}
              md={6}
              /* onClick={() => setDoQuiz({ theme, do: true })} */
            >
              <Card
                style={{ marginBottom: "1em" }}
                className="clickable-card"
                onClick={() => navigate(getSectorKey(sector))}
              >
                <Card.Body>
                  <h5>{sector}</h5>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ChartsQuiz;
