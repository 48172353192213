import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

interface IProps {
  children: React.ReactNode;
}

const QuizNavBar: React.FC<IProps> = ({ children }) => {
  return (
    <Navbar
      bg="light"
      variant="light"
      fixed="bottom"
      style={{ height: "80px" }}
    >
      <Container>{children}</Container>
    </Navbar>
  );
};

export default QuizNavBar;
