import React from "react";
import { Button, Card, Col, Row, ToggleButton } from "react-bootstrap";
import QuizImage from "../quizImage";
import { FaQuestionCircle } from "react-icons/fa";

interface IProps {
  question: any;
  questionIndex: number;
  questionsWithAnswer: any[];
  numOfQuestions: number;
  wereAnswersChecked: boolean;
  setShowImageModal: ({
    show,
    url,
    progressive,
  }: {
    show: boolean;
    url: string;
    progressive: string;
  }) => void;
  setShowExplanationModal: ({
    show,
    title,
    explanation,
    progressive,
  }: {
    show: boolean;
    title: string;
    explanation: string;
    progressive: string;
  }) => void;
  setAnswer: (questionIndex: number, answerIndex: number) => void;
  scrollFunction: () => void;
}

const QuizQuestion: React.FC<IProps> = ({
  question,
  questionIndex,
  questionsWithAnswer,
  numOfQuestions,
  wereAnswersChecked,
  setShowImageModal,
  setShowExplanationModal,
  setAnswer,
  scrollFunction,
}) => {
  return (
    <Row
      className={`question-box ${
        questionIndex === 0
          ? "first"
          : questionIndex === numOfQuestions - 1
          ? "last"
          : ""
      }`}
      style={{
        /* borderTopLeftRadius: questionIndex === 0 ? "5px" : "",
        borderTopRightRadius: questionIndex === 0 ? "5px" : "",
        borderBottomLeftRadius:
          questionIndex === numOfQuestions - 1 ? "5px" : "",
        borderBottomRightRadius:
          questionIndex === numOfQuestions - 1 ? "5px" : "", */
        borderRadius: "5px",
      }}
      id={`id-question-${questionIndex}`}
      key={`question-${questionIndex}`}
    >
      <Col
        className={`question-box-answer-col ${
          question?.picture != null ? "with-image" : ""
        }`}
      >
        <Card style={{ border: "none" }}>
          <Card.Title>
            {questionIndex + 1}
            {". "}
            {question.text}
          </Card.Title>
          <Card.Body>
            <Col>
              {/* {question?.picture != null && (
                <Col xs={12} sm={0}>
                  <QuizImage
                    url={`../quiz-img/${question.picture}.png`}
                    handleClick={() => {
                      setShowImageModal({
                        show: true,
                        url: `../quiz-img/${question.picture}.png`,
                        progressive: question.progressive,
                      });
                    }}
                  />
                </Col>
              )} */}
              {question.answers.map((answer: any, answerIndex: number) => {
                return (
                  <div key={`div-${answerIndex}-q-${questionIndex}-row`}>
                    <Row key={`answer-${answerIndex}-q-${questionIndex}-row`}>
                      <Col xs={12}>
                        <ToggleButton
                          disabled={wereAnswersChecked}
                          key={`answer-${answerIndex}-q-${questionIndex}-btn`}
                          id={`answer-${answerIndex}-q-${questionIndex}-btn-id`}
                          name={`answer-${answerIndex}-q-${questionIndex}-btn-name`}
                          type="radio"
                          variant="outline-primary"
                          value={answerIndex}
                          checked={
                            questionsWithAnswer?.find(
                              (q) =>
                                q.progressive === question.progressive &&
                                q.answerGiven?.index === answerIndex
                            ) != null
                          }
                          onChange={(e) => {
                            setAnswer(
                              questionIndex,
                              Number(e.currentTarget.value)
                            );
                            scrollFunction();
                          }}
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          <span
                            className={
                              wereAnswersChecked
                                ? answer.isCorrect
                                  ? "highlight-green"
                                  : questionsWithAnswer?.find(
                                      (q) =>
                                        q.progressive ===
                                          question.progressive &&
                                        q.answerGiven?.index === answerIndex
                                    ) != null
                                  ? "highlight-red"
                                  : ""
                                : ""
                            }
                          >
                            {answer.text}
                          </span>
                        </ToggleButton>
                      </Col>
                      {/* <Col xs={11}>
                      </Col> */}
                    </Row>
                    <br />
                    {/* {answerIndex < question.answers.length - 1 && (
                      <hr key={`answer-${answerIndex}-q-${questionIndex}-hr`} />
                    )} */}
                  </div>
                );
              })}
            </Col>
          </Card.Body>
          {wereAnswersChecked && !!question.explanation && (
            <Card.Footer style={{ backgroundColor: "white" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "40px",
                }}
              >
                <Button
                  onClick={() =>
                    setShowExplanationModal({
                      show: true,
                      title: question.text,
                      explanation: question.explanation,
                      progressive: question.progressive,
                    })
                  }
                >
                  <span style={{ marginRight: "1em" }}>Spiegazione</span>
                  <FaQuestionCircle />
                </Button>
              </div>
            </Card.Footer>
          )}
        </Card>
      </Col>

      {question?.picture != null && (
        <Col xs={0} sm={1} md={3} style={{ paddingTop: "1em" }}>
          <QuizImage
            url={`../quiz-img/${question.picture}.png`}
            handleClick={() => {
              setShowImageModal({
                show: true,
                url: `../quiz-img/${question.picture}.png`,
                progressive: question.progressive,
              });
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default QuizQuestion;
