import React from "react";
import Container from "react-bootstrap/Container";
import useQuiz from "../../hooks/useQuiz";
import { NUM_OF_SAIL_EXAM_QUESTIONS, SAIL_EXAM_TIME } from "../../constants";
import SimulationVertical from "../../components/simulationVertical";

const SailSimulationPartial: React.FC = () => {
  const { createSailExamLimitedSimulation } = useQuiz();

  React.useEffect(() => {
    document.title = "Nautiquiz - Simulazione Vela per categorie";
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <h1>Esame parziale</h1>
      <p>
        L'esame sarà composto da <b>{NUM_OF_SAIL_EXAM_QUESTIONS} domande</b>,
        ognuna con <b>3 possibili risposte</b>, di cui una è corretta.
      </p>
      <p>
        Il tempo a tua disposizione è di <b>{SAIL_EXAM_TIME / 60} minuti</b>.
      </p>
      <p>Puoi scegliere un insieme ristretto di argomenti.</p>
      <SimulationVertical
        partial
        numOfQuestions={NUM_OF_SAIL_EXAM_QUESTIONS}
        examTime={SAIL_EXAM_TIME}
        createExamSimulation={createSailExamLimitedSimulation}
        localStorageKey="sailQuestions"
      />
    </Container>
  );
};

export default SailSimulationPartial;
