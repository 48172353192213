import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import useQuiz from "../../hooks/useQuiz";
import { Row, Col, Card } from "react-bootstrap";
import Simulation from "../../components/simulation";
import { useStats } from "../../hooks/useStats";

interface IProps {
  questionsFromLS: string;
  themes: string[];
}

const Errors: React.FC<IProps> = ({ questionsFromLS, themes }) => {
  const { createExamErrorSimulation } = useQuiz();
  const { numOfWronglyAnsweredQuiz } = useStats();

  const [doQuiz, setDoQuiz] = useState({ theme: "", do: false });

  const [themesWithErrors, setThemesWithErrors] = useState<
    (JSX.Element | null)[]
  >([]);
  React.useEffect(() => {
    document.title = "Nautiquiz - Motore - I tuoi errori";
  });

  React.useEffect(() => {
    const currentThemesWithErrors = themes?.map((theme, i) => {
      const numOfErrorsForCurrentTheme = numOfWronglyAnsweredQuiz(
        JSON.parse(questionsFromLS),
        theme
      );

      return numOfErrorsForCurrentTheme === 0 ? null : (
        <Col
          key={i}
          xs={12}
          md={6}
          onClick={() => setDoQuiz({ theme, do: true })}
        >
          <Card style={{ marginBottom: "1em" }} className="clickable-card">
            <Card.Body>
              <h5>{theme}</h5>
              <p>
                <b>Domande con errori:</b> {numOfErrorsForCurrentTheme}
              </p>
            </Card.Body>
          </Card>
        </Col>
      );
    });
    setThemesWithErrors(currentThemesWithErrors.filter((item) => !!item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsFromLS, themes]);

  return (
    <Container style={{ marginTop: "80px" }}>
      <Container style={{ maxWidth: "800px" }}>
        {doQuiz?.do && doQuiz?.theme ? (
          <Simulation
            numOfQuestions={numOfWronglyAnsweredQuiz(
              JSON.parse(questionsFromLS),
              doQuiz.theme
            )}
            createExamSimulation={() =>
              createExamErrorSimulation(
                JSON.parse(questionsFromLS),
                doQuiz.theme
              )
            }
            questionsFromLSWithAnswersKey="errorsQuestions"
          ></Simulation>
        ) : themesWithErrors?.length > 0 ? (
          <Row>{themesWithErrors}</Row>
        ) : (
          <>
            <img
              src="/popeye-happy.png"
              alt="ok"
              height="200px"
              style={{ marginTop: "2em" }}
            />
            <h1 style={{ marginTop: "100px" }}>Nessun errore finora</h1>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Errors;
