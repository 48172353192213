import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import useQuiz from "../../hooks/useQuiz";
import { Row, Col, Card } from "react-bootstrap";
import Simulation from "../../components/simulation";
import { useStats } from "../../hooks/useStats";
import { TrainingQuestionsWithAnswersKey } from "../../pages/base/training";

interface IProps {
  questionsFromLS: string;
  themes: string[];
  questionsFromLSWithAnswersKey: TrainingQuestionsWithAnswersKey;
}

const Training: React.FC<IProps> = ({
  questionsFromLS,
  themes,
  questionsFromLSWithAnswersKey,
}) => {
  const { createExamTrainingSimulation } = useQuiz();
  const { numOfNotAnsweredQuiz } = useStats();

  const [doQuiz, setDoQuiz] = useState({ theme: "", do: false });

  React.useEffect(() => {
    document.title = "Nautiquiz - Argomenti Motore";
  });

  const [argumentsWithQuestionsToDo, setArgumentsWithQuestionsToDo] = useState<
    (JSX.Element | null)[]
  >([]);

  React.useEffect(() => {
    const currentArgumentsToShow = themes?.map((theme, i) => {
      const numOfQuizToDo = numOfNotAnsweredQuiz(
        JSON.parse(questionsFromLS),
        theme
      );
      return numOfQuizToDo === 0 ? null : (
        <Col
          key={i}
          xs={12}
          md={6}
          onClick={() => setDoQuiz({ theme, do: true })}
        >
          <Card style={{ marginBottom: "1em" }} className="clickable-card">
            <Card.Body>
              <h5>{theme}</h5>
              <p>
                <b>Domande da fare:</b>{" "}
                {numOfNotAnsweredQuiz(JSON.parse(questionsFromLS), theme)}
              </p>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    setArgumentsWithQuestionsToDo(
      currentArgumentsToShow.filter((item) => !!item)
    );
  }, []);

  return (
    <Container style={{ marginTop: "80px" }}>
      <Container style={{ maxWidth: "800px" }}>
        {doQuiz?.do && doQuiz?.theme ? (
          <Simulation
            numOfQuestions={numOfNotAnsweredQuiz(
              JSON.parse(questionsFromLS),
              doQuiz.theme
            )}
            createExamSimulation={() =>
              createExamTrainingSimulation(
                JSON.parse(questionsFromLS),
                doQuiz.theme
              )
            }
            questionsFromLSWithAnswersKey={questionsFromLSWithAnswersKey}
          ></Simulation>
        ) : (
          <Row>{argumentsWithQuestionsToDo}</Row>
        )}
      </Container>
    </Container>
  );
};

export default Training;
