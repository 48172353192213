import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Navbar bg="light" variant="light" fixed="top" collapseOnSelect expand="sm">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Nautiquiz
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={pathname}>
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/stats">Statistiche</Nav.Link>
            </Nav.Item>
            <NavDropdown
              title="Motore"
              id="basic-nav-dropdown"
              active={pathname.includes("/base")}
            >
              <NavDropdown.Item
                href="/base/simulation"
                active={pathname === "/base/simulation"}
              >
                Esame completo
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/base/simulation-partial"
                active={pathname === "/base/simulation-partial"}
              >
                Esame parziale
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/base/errors"
                active={pathname === "/base/errors"}
              >
                Errori
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/base/training"
                active={pathname === "/base/training"}
              >
                Argomenti
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Vela"
              id="basic-nav-dropdown"
              active={pathname.includes("/sail")}
            >
              <NavDropdown.Item
                href="/sail/simulation"
                active={pathname === "/sail/simulation"}
              >
                Esame completo
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/sail/simulation-partial"
                active={pathname === "/sail/simulation-partial"}
              >
                Esame parziale
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/sail/errors"
                active={pathname === "/sail/errors"}
              >
                Errori
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/sail/training"
                active={pathname === "/sail/training"}
              >
                Argomenti
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Carteggio"
              id="basic-nav-dropdown"
              active={pathname.includes("/charts")}
            >
              <NavDropdown.Item
                href="/charts/quiz"
                active={pathname === "/charts/quiz"}
              >
                Quiz
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/charts/simulation-5d"
                active={pathname === "/charts/simulation-5d"}
              >
                Prova di carteggio - 5D
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/charts/simulation-42d"
                active={pathname === "/charts/simulation-42d"}
              >
                Prova di carteggio - 42D
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/charts/deviation-table"
                active={pathname === "/charts/deviation-table"}
              >
                Tabella di Deviazione
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link href="/donate">Supporta Nautiquiz</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
