import React from "react";
import useQuiz from "../../hooks/useQuiz";
import { useLocalStorage } from "usehooks-ts";
import Errors from "../../components/errors/errors";

const BaseErrors: React.FC = () => {
  const { baseQuiz } = useQuiz();
  const [baseQuestionsFromLS] = useLocalStorage(
    "baseQuestions",
    JSON.stringify(baseQuiz?.questions)
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Errors questionsFromLS={baseQuestionsFromLS} themes={baseQuiz.themes} />
  );
};

export default BaseErrors;
