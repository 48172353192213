import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const Base: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <h1>Quiz Motore</h1>
      <>
        <Button
          variant="outline-primary"
          onClick={() => navigate("/base/simulation")}
          style={{ marginRight: "1em" }}
        >
          Esame motore completo
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => navigate("/base/simulation-partial")}
          style={{ marginRight: "1em" }}
        >
          Esame motore parziale
        </Button>
      </>
    </Container>
  );
};

export default Base;
