import React, { useEffect, useState } from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useLocalStorage } from "usehooks-ts";
import SelectQuizCard from "../components/selectQuizCard/selectQuizCard";
import useQuiz from "../hooks/useQuiz";
import { isEmpty } from "lodash";
import baseQuiz from "../quiz/base-quiz.json";
import sailQuiz from "../quiz/sail-quiz.json";

export const useStats = () => {
  const [baseQuestionsFromLS, setBaseQuestionsFromLS] = useLocalStorage(
    "baseQuestions",
    JSON.stringify(baseQuiz?.questions)
  );
  const [sailQuestionsFromLS, setSailQuestionsFromLS] = useLocalStorage(
    "sailQuestions",
    JSON.stringify(sailQuiz?.questions)
  );

  const { createExamErrorSimulation } = useQuiz();

  const [numOfErrorsBase, setNumOfErrorsBase] = useState(0);
  const [numOfErrorsSail, setNumOfErrorsSail] = useState(0);

  const [numOfAnsweredBase, setNumOfAnsweredBase] = useState(0);
  const [numOfAnsweredSail, setNumOfAnsweredSail] = useState(0);

  const [isStatisticPresent, setIsStatisticPresent] = useState(false);

  useEffect(() => {
    if (isEmpty(baseQuestionsFromLS) && isEmpty(sailQuestionsFromLS)) {
      setIsStatisticPresent(false);
    } else {
      setIsStatisticPresent(true);
      setNumOfErrorsBase(
        numOfWronglyAnsweredQuiz(JSON.parse(baseQuestionsFromLS))
      );
      setNumOfErrorsSail(
        numOfWronglyAnsweredQuiz(JSON.parse(sailQuestionsFromLS))
      );
      setNumOfAnsweredBase(numOfAnsweredQuiz(JSON.parse(baseQuestionsFromLS)));
      setNumOfAnsweredSail(numOfAnsweredQuiz(JSON.parse(sailQuestionsFromLS)));
    }
  }, [baseQuestionsFromLS, sailQuestionsFromLS]);

  /**
   * Per Themes Stats
   */
  const numOfWronglyAnsweredQuiz = (
    quizFromLS: any[] = [],
    theme: string = ""
  ): number => {
    const quizToConsider = createExamErrorSimulation(quizFromLS, theme);
    return (
      quizToConsider.filter((q: any) => q.hasError && !q.correctAtLeastOnce)
        ?.length ?? 0
    );
  };

  const numOfAnsweredQuiz = (
    quizFromLS: any[] = [],
    theme: string = ""
  ): number => {
    let quizToConsider = JSON.parse(JSON.stringify(quizFromLS));
    if (theme) {
      quizToConsider = quizToConsider.filter((q: any) => q.theme === theme);
    }
    return (
      quizToConsider.filter((q: any) => q.answeredAtLeastOnce)?.length ?? 0
    );
  };

  const numOfNotAnsweredQuiz = (
    quizFromLS: any[] = [],
    theme: string = ""
  ): number => {
    let quizToConsider = JSON.parse(JSON.stringify(quizFromLS));
    if (theme) {
      quizToConsider = quizToConsider.filter((q: any) => q.theme === theme);
    }
    return (
      quizToConsider.filter((q: any) => !q.answeredAtLeastOnce)?.length ?? 0
    );
  };

  return {
    isStatisticPresent,
    numOfErrorsBase,
    numOfErrorsSail,
    numOfAnsweredBase,
    numOfAnsweredSail,
    numOfBaseQuestions: baseQuiz?.questions?.length,
    numOfSailQuestions: sailQuiz?.questions.length,
    numOfWronglyAnsweredQuiz,
    numOfAnsweredQuiz,
    numOfNotAnsweredQuiz,
  };
};
