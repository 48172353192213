import React from "react";
import { Col, Card, Accordion } from "react-bootstrap";
import Container from "react-bootstrap/Container";

interface IProps {
  title: string;
  breadcrumbs: React.ReactElement;
  quiz: any;
  filterFieldName: string;
  filterFieldValue: string;
}

const ChartsQuizList: React.FC<IProps> = ({
  title,
  breadcrumbs,
  quiz,
  filterFieldName,
  filterFieldValue,
}) => {
  return (
    <Container style={{ marginTop: "80px" }}>
      {breadcrumbs}
      <Container style={{ maxWidth: "800px" }}>
        <h4>{title}</h4>
        {quiz.questions
          .filter((q: any) => q[filterFieldName] === filterFieldValue)
          .map((question: any, i: number) => {
            return (
              <Col key={i} xs={12}>
                <Card style={{ marginBottom: "1em" }}>
                  <Card.Header style={{ textAlign: "start" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {question.progressive}
                      </span>
                      {/* <Button color="primary">Segnala anomalia</Button> */}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div style={{ whiteSpace: "pre-wrap", textAlign: "start" }}>
                      {question.text}
                    </div>
                  </Card.Body>
                  {question.answers &&
                    question.answers.find((a: any) => !!a.text) && (
                      <Accordion>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            borderTop: "1px solid",
                            borderBottom: "0",
                            borderLeft: "0",
                            borderRight: "0",
                            borderTopLeftRadius: "0",
                            borderTopRightRadius: "0",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderColor: "lightgray",
                          }}
                        >
                          <Accordion.Header>
                            <b>Soluzioni</b>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ol>
                              {question.answers.map(
                                (answer: any, i: number) => {
                                  if (!answer.text) {
                                    return;
                                  }
                                  return (
                                    <li key={i} style={{ textAlign: "start" }}>
                                      {answer.text}
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                </Card>
              </Col>
            );
          })}
      </Container>
    </Container>
  );
};

export default ChartsQuizList;
