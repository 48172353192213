import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useStats } from "../hooks/useStats";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export const StatsPage: React.FC = () => {
  const {
    isStatisticPresent,
    numOfErrorsBase,
    numOfErrorsSail,
    numOfAnsweredBase,
    numOfAnsweredSail,
    numOfBaseQuestions,
    numOfSailQuestions,
  } = useStats();

  const baseChartData = {
    labels: ["Sbagliate", "Corrette"],
    datasets: [
      {
        data: [numOfErrorsBase, numOfAnsweredBase - numOfErrorsBase],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const sailChartData = {
    labels: ["Sbagliate", "Corrette"],
    datasets: [
      {
        data: [numOfErrorsSail, numOfAnsweredSail - numOfErrorsSail],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const DoYourJob: React.FC = () => {
    return (
      <>
        <img
          src="/popeye-sad.png"
          alt="ok"
          height="200px"
          style={{ marginTop: "2em" }}
        />
        <h4 style={{ marginTop: "1em" }}>
          Non hai ancora svolto nessun esercizio! Mettiti al lavoro!
        </h4>
      </>
    );
  };

  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      {!isStatisticPresent ||
      (numOfAnsweredBase === 0 && numOfAnsweredSail === 0) ? (
        <DoYourJob />
      ) : (
        <Container>
          <Row>
            <Col>
              <Card
                style={{
                  marginBottom: "1em",
                }}
              >
                <Card.Header>
                  <h4>Quiz Base</h4>
                </Card.Header>
                <Card.Body>
                  {numOfAnsweredBase === 0 && <DoYourJob />}
                  {numOfAnsweredBase > 0 && (
                    <Row>
                      <Col>
                        <Pie data={baseChartData} />
                      </Col>
                      <Col>
                        <div style={{ marginTop: "1em", textAlign: "start" }}>
                          <p>
                            Hai risposto a <b>{numOfAnsweredBase}</b> domande in
                            totale
                          </p>
                          <p>
                            Ti mancano{" "}
                            <b>{numOfBaseQuestions - numOfAnsweredBase}</b>{" "}
                            domande per essere un marinaio!
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
            <Col>
              <Card
                style={{
                  marginBottom: "1em",
                }}
              >
                <Card.Header>
                  <h4>Quiz Vela</h4>
                </Card.Header>
                <Card.Body>
                  {numOfAnsweredSail === 0 && <DoYourJob />}
                  {numOfAnsweredSail > 0 && (
                    <Row>
                      <Col>
                        <Pie data={sailChartData} />
                      </Col>
                      <Col>
                        <>
                          <div style={{ marginTop: "1em", textAlign: "start" }}>
                            <p>
                              Hai risposto a <b>{numOfAnsweredSail}</b> domande
                              in totale
                            </p>
                            <p>
                              Ti mancano{" "}
                              <b>{numOfSailQuestions - numOfAnsweredSail}</b>{" "}
                              domande per essere un vero velista!
                            </p>
                          </div>
                        </>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};
