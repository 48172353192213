interface NumOfExamQuestionsMap {
  [key: string]: number;
}
export const baseThemesNumberOfQuestionsMap: NumOfExamQuestionsMap = {
  "TEORIA DELLO SCAFO": 1,
  MOTORI: 1,
  "SICUREZZA DELLA NAVIGAZIONE": 3,
  "MANOVRA E CONDOTTA": 4,
  "COLREG E SEGNALAMENTO MARITTIMO": 2,
  METEOROLOGIA: 2,
  "NAVIGAZIONE CARTOGRAFICA ED ELETTRONICA": 4,
  "NORMATIVA DIPORTISTICA E AMBIENTALE": 3,
};

export const MAX_BASE_ERRORS_ALLOWED = 4;
export const MAX_SAIL_ERRORS_ALLOWED = 1;
export const NUM_OF_BASE_EXAM_QUESTIONS = 20;
export const NUM_OF_SAIL_EXAM_QUESTIONS = 5;
export const BASE_EXAM_TIME = 60 * 30; // 30 minutes
export const SAIL_EXAM_TIME = 60 * 15; // 15 minutes
