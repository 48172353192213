import { Container } from "react-bootstrap";

export const DonatePage = () => {
  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <div style={{ textAlign: "center" }}>
        <h2>Supporta lo sviluppo di NautiQuiz.</h2>
        <p>
          Ogni contributo, anche piccolo, ci aiuta a fornirvi{" "}
          <b>domande aggiornate</b>e una <b>piattaforma affidabile</b>.
        </p>
        <p>
          Cliccando sul pulsante <b>Donazione</b> per donare tramite PayPal
          supporterete lo sviluppatore nel lavoro di manutenzione e
          miglioramento di quest'app e ai costi per tenerla online.
        </p>
        <p>
          <b>
            Grazie per il vostro sostegno e per far parte della nostra comunità.
          </b>
        </p>
      </div>
      <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="67Q36DBTYF7EU" />
        <input
          type="image"
          src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Fai una donazione con il pulsante PayPal"
        />
        <img
          alt=""
          src="https://www.paypal.com/it_IT/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </Container>
  );
};
