import React from "react";
import Container from "react-bootstrap/Container";
import { NUM_OF_SAIL_EXAM_QUESTIONS, SAIL_EXAM_TIME } from "../../constants";
import useQuiz from "../../hooks/useQuiz";
import SimulationVertical from "../../components/simulationVertical";
import { Breadcrumb } from "react-bootstrap";

const SailSimulation: React.FC = () => {
  const { createSailExamSimulation } = useQuiz();

  React.useEffect(() => {
    document.title = "Nautiquiz - Simulazione Esame Vela";
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ marginTop: "80px" }}>
      <h1>Modalità di esame</h1>
      <p>
        L'esame sarà composto da <b>{NUM_OF_SAIL_EXAM_QUESTIONS} domande</b>,
        ognuna con <b>3 possibili risposte</b>, di cui una è corretta.
      </p>
      <p>
        Il tempo a tua disposizione è di <b>{SAIL_EXAM_TIME / 60} minuti</b>.
      </p>
      <p>Puoi scegliere di escludere i quiz già svolti in precedenza.</p>
      {/*<Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#">Esame vela completo</Breadcrumb.Item>
      </Breadcrumb> */}
      <Container style={{ maxWidth: "800px" }}>
        <SimulationVertical
          numOfQuestions={NUM_OF_SAIL_EXAM_QUESTIONS}
          examTime={SAIL_EXAM_TIME}
          createExamSimulation={createSailExamSimulation}
          localStorageKey="sailQuestions"
        />
      </Container>
    </Container>
  );
};

export default SailSimulation;
