import React from "react";
import Container from "react-bootstrap/Container";
import SimulationVertical from "../../components/simulationVertical";
import { BASE_EXAM_TIME, NUM_OF_BASE_EXAM_QUESTIONS } from "../../constants";
import useQuiz from "../../hooks/useQuiz";

const BaseSimulation: React.FC = () => {
  const { createBaseExamSimulation } = useQuiz();

  React.useEffect(() => {
    document.title = "Nautiquiz - Simulazione Esame Motore";
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ marginTop: "80px" }}>
      <Container style={{ maxWidth: "800px" }}>
        <h1>Modalità di esame</h1>
        <p>
          L'esame sarà composto da <b>{NUM_OF_BASE_EXAM_QUESTIONS} domande</b>,
          ognuna con <b>3 possibili risposte</b>, di cui una è corretta.
        </p>
        <p>
          Il tempo a tua disposizione è di <b>{BASE_EXAM_TIME / 60} minuti</b>.
        </p>
        <p>Puoi scegliere di escludere i quiz già svolti in precedenza.</p>
        <SimulationVertical
          numOfQuestions={NUM_OF_BASE_EXAM_QUESTIONS}
          examTime={BASE_EXAM_TIME}
          createExamSimulation={createBaseExamSimulation}
          localStorageKey="baseQuestions"
        />
      </Container>
    </Container>
  );
};

export default BaseSimulation;
