import { Breadcrumb } from "react-bootstrap";

interface IBreadcrumbsPathElement {
  name: string;
  url: string;
}

interface IBreadcrumbsProps {
  path: IBreadcrumbsPathElement[];
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ path }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item key={`breadcrumb-el-root`} href="/home">
        Home
      </Breadcrumb.Item>
      {path.map((el, i) => {
        return (
          <Breadcrumb.Item key={`breadcrumb-el-${i}`} href={el.url}>
            {el.name}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
