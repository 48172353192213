import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPaginate from "react-paginate";
import { FaQuestionCircle } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { useLocalStorage } from "usehooks-ts";
import { TrainingQuestionsWithAnswersKey } from "../pages/base/training";
import baseQuiz from "../quiz/base-quiz.json";
import sailQuiz from "../quiz/sail-quiz.json";

interface IProps {
  numOfQuestions: number;
  questionsFromLSWithAnswersKey: TrainingQuestionsWithAnswersKey;
  includedCategories?: string[];
  createExamSimulation: () => any[];
}

const Simulation: React.FC<IProps> = ({
  numOfQuestions,
  questionsFromLSWithAnswersKey,
  createExamSimulation,
}) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [questionsWithAnswer, setQuestionsWithAnswer] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);

  /* const [questionsFromLSWithAnswers, setQuestionsFromLSWithAnswers] =
    useLocalStorage(
      questionsFromLSWithAnswersKey,
      JSON.stringify(
        questionsFromLSWithAnswersKey === "trainingBaseQuestions"
          ? baseQuiz?.questions
          : questionsFromLSWithAnswersKey === "trainingSailQuestions"
          ? sailQuiz?.questions
          : ""
      )
    ); */

  const [questionsFromLS, setQuestionsFromLS] = useLocalStorage(
    questionsFromLSWithAnswersKey === "trainingBaseQuestions"
      ? "baseQuestions"
      : questionsFromLSWithAnswersKey === "trainingSailQuestions"
      ? "sailQuestions"
      : "",
    JSON.stringify(
      questionsFromLSWithAnswersKey === "trainingBaseQuestions"
        ? baseQuiz?.questions
        : questionsFromLSWithAnswersKey === "trainingSailQuestions"
        ? sailQuiz?.questions
        : ""
    )
  );

  useEffect(() => {
    setQuestions(createExamSimulation());
    setQuestionsWithAnswer([]);
    setCurrentQuestionIndex(1);
    /* setQuestionsFromLSWithAnswers(
      JSON.stringify(
        questionsFromLSWithAnswersKey === "trainingBaseQuestions"
          ? baseQuiz?.questions
          : sailQuiz?.questions
      )
    ); */
  }, []);

  const goToPage = (page: number) => {
    setCurrentQuestionIndex(page);
  };

  const setAnswer = useCallback(
    (answerIndex: number) => {
      const currentQuestionProgressive =
        questions[currentQuestionIndex - 1].progressive;
      const isCorrect =
        questions[currentQuestionIndex - 1]?.answers[answerIndex].isCorrect;
      saveAnswerInTrainingQuestions(isCorrect, currentQuestionProgressive);
      const currentQuestionWithAnswer = {
        progressive: currentQuestionProgressive,
        answerGiven: {
          index: answerIndex,
          text: questions[currentQuestionIndex - 1]?.answers[answerIndex].text,
        },
        isCorrect: isCorrect,
        currentQuizQuestionIndex: currentQuestionIndex - 1,
        correctAnswer: {
          ...questions[currentQuestionIndex - 1]?.answers.find(
            (ans: any) => ans.isCorrect
          ),
          index: questions[currentQuestionIndex - 1]?.answers?.indexOf(
            questions[currentQuestionIndex - 1]?.answers.find(
              (ans: any) => ans.isCorrect
            )
          ),
        },
        text: questions[currentQuestionIndex - 1].text,
      };
      const currentQuestionsCopy = [...questionsWithAnswer]?.filter(
        (q) => q.progressive !== currentQuestionWithAnswer.progressive
      );
      currentQuestionsCopy.push(currentQuestionWithAnswer);
      setQuestionsWithAnswer(currentQuestionsCopy);
    },
    [questions, currentQuestionIndex]
  );

  const saveAnswerInTrainingQuestions = (
    isCorrect: boolean,
    progressive: number
  ) => {
    const newQuestionsFromLSWithAnswers = JSON.parse(questionsFromLS)?.map(
      (q: any) => {
        if (q.progressive === progressive) {
          // update question inside the structure
          if (!isCorrect) {
            q.hasError = true;
          } else {
            q.correctAtLeastOnce = true;
          }
          q.answeredAtLeastOnce = true;
        }

        return q;
      }
    );

    setQuestionsFromLS(JSON.stringify(newQuestionsFromLSWithAnswers));
  };

  const [showExplanationModal, setShowExplanationModal] = useState({
    show: false,
    title: "",
    explanation: "",
    progressive: "",
  });

  return (
    <Container>
      <>
        {questions?.length > 0 && currentQuestionIndex <= numOfQuestions && (
          <Container>
            <Row style={{ minHeight: "320px" }}>
              <Col lg={2}></Col>
              <Col xs={12} lg={8}>
                {questions[currentQuestionIndex - 1]?.picture != null && (
                  <img
                    src={`../quiz-img/${
                      questions[currentQuestionIndex - 1].picture
                    }.png`}
                    alt="pic"
                  ></img>
                )}
                <p>
                  <span style={{ fontWeight: 500, fontSize: "1.25rem" }}>
                    {questions[currentQuestionIndex - 1]?.text}
                  </span>
                </p>
                <Button
                  onClick={() => {
                    setAnswer(0);
                    const elements =
                      document.getElementsByClassName("page-link");
                    Array.prototype.forEach.call(elements, function (el) {
                      // Do stuff here
                      if (currentQuestionIndex == el.innerHTML) {
                        el.classList.add("answerButton-selected");
                      }
                    });
                  }}
                  disabled={questionsWithAnswer.find(
                    (q) =>
                      q.currentQuizQuestionIndex === currentQuestionIndex - 1 &&
                      !!q.answerGiven
                  )}
                  className={`answerButton ${
                    questionsWithAnswer.find(
                      (q) =>
                        q.currentQuizQuestionIndex ===
                          currentQuestionIndex - 1 &&
                        q.correctAnswer.index === 0
                    )
                      ? "correctAnswer"
                      : questionsWithAnswer.find(
                          (q) =>
                            q.currentQuizQuestionIndex ===
                              currentQuestionIndex - 1 &&
                            q.answerGiven.index === 0 &&
                            q.answerGiven.index !== q.correctAnswer.index
                        )
                      ? "wrongAnswer"
                      : "notRelevantAnswer"
                  }`}
                >
                  {questions[currentQuestionIndex - 1]?.answers[0]?.text}
                </Button>
                <p></p>
                <Button
                  onClick={() => {
                    setAnswer(1);
                    const elements =
                      document.getElementsByClassName("page-link");
                    Array.prototype.forEach.call(elements, function (el) {
                      // Do stuff here
                      if (currentQuestionIndex == el.innerHTML) {
                        el.classList.add("answerButton-selected");
                      }
                    });
                  }}
                  disabled={questionsWithAnswer.find(
                    (q) =>
                      q.currentQuizQuestionIndex === currentQuestionIndex - 1 &&
                      !!q.answerGiven
                  )}
                  className={`answerButton ${
                    questionsWithAnswer.find(
                      (q) =>
                        q.currentQuizQuestionIndex ===
                          currentQuestionIndex - 1 &&
                        q.correctAnswer.index === 1
                    )
                      ? "correctAnswer"
                      : questionsWithAnswer.find(
                          (q) =>
                            q.currentQuizQuestionIndex ===
                              currentQuestionIndex - 1 &&
                            q.answerGiven.index === 1 &&
                            q.answerGiven.index !== q.correctAnswer.index
                        )
                      ? "wrongAnswer"
                      : "notRelevantAnswer"
                  }`}
                >
                  {questions[currentQuestionIndex - 1]?.answers[1]?.text}
                </Button>
                {questions[currentQuestionIndex - 1]?.answers[2] && (
                  <>
                    <p></p>
                    <Button
                      onClick={() => {
                        setAnswer(2);
                        const elements =
                          document.getElementsByClassName("page-link");
                        Array.prototype.forEach.call(elements, function (el) {
                          // Do stuff here
                          if (currentQuestionIndex == el.innerHTML) {
                            el.classList.add("answerButton-selected");
                          }
                        });
                      }}
                      disabled={questionsWithAnswer.find(
                        (q) =>
                          q.currentQuizQuestionIndex ===
                            currentQuestionIndex - 1 && !!q.answerGiven
                      )}
                      className={`answerButton ${
                        questionsWithAnswer.find(
                          (q) =>
                            q.currentQuizQuestionIndex ===
                              currentQuestionIndex - 1 &&
                            q.correctAnswer.index === 2
                        )
                          ? "correctAnswer"
                          : questionsWithAnswer.find(
                              (q) =>
                                q.currentQuizQuestionIndex ===
                                  currentQuestionIndex - 1 &&
                                q.answerGiven.index === 2 &&
                                q.answerGiven.index !== q.correctAnswer.index
                            )
                          ? "wrongAnswer"
                          : "notRelevantAnswer"
                      }`}
                    >
                      {questions[currentQuestionIndex - 1]?.answers[2]?.text}
                    </Button>
                  </>
                )}
              </Col>
              <Col></Col>
            </Row>
            <br />
            {!!questions[currentQuestionIndex - 1]?.explanation && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40px",
                }}
              >
                <Button
                  onClick={() =>
                    setShowExplanationModal({
                      show: true,
                      title: questions[currentQuestionIndex - 1]?.text,
                      explanation:
                        questions[currentQuestionIndex - 1]?.explanation,
                      progressive:
                        questions[currentQuestionIndex - 1]?.progressive,
                    })
                  }
                >
                  <span style={{ marginRight: "1em" }}>Spiegazione</span>
                  <FaQuestionCircle />
                </Button>
              </div>
            )}
            <br />
            {/* <Row>
              <ResponsivePagination
                current={currentQuestionIndex}
                size={questions?.length}
                onPageChange={(page) => goToPage(page)}
                questionsWithAnswer={questionsWithAnswer}
                isShowingResult={isShowingResult}
              />
            </Row> */}
            <Row style={{ maxWidth: "100vw" }}>
              <Col></Col>
              <Col>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="»"
                  onPageChange={(page) => goToPage(page.selected + 1)}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={4}
                  pageCount={questions?.length}
                  previousLabel="«"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={() => null}
                />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </>
      <Modal
        show={showExplanationModal.show}
        onHide={() =>
          setShowExplanationModal({
            show: false,
            title: "",
            explanation: "",
            progressive: "",
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>{showExplanationModal.title}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-sm-center">
            <p>{showExplanationModal.explanation}</p>
            <i>Quesito {showExplanationModal.progressive}</i>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Simulation;
