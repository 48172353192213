import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTimer } from "react-timer-hook";
import { useLocalStorage } from "usehooks-ts";
import { Checkbox } from "pretty-checkbox-react";
import QuizQuestion from "./quizQuestion/quizQuestion";
import ScrollToTop from "react-scroll-to-top";
import QuizNavBar from "./quizNavBar/quizNavBar";
import baseQuiz from "../quiz/base-quiz.json";
import sailQuiz from "../quiz/sail-quiz.json";

export const FancyCheckbox = (props: any) => (
  <Checkbox name="my-input" checked={true}>
    Checked and disabled
  </Checkbox>
);

interface IProps {
  numOfQuestions: number;
  examTime: number;
  createExamSimulation: Function;
  localStorageKey: string;
  partial?: boolean;
}

const SimulationVertical: React.FC<IProps> = ({
  numOfQuestions,
  examTime,
  createExamSimulation,
  localStorageKey,
  partial = false,
}) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [questionsWithAnswer, setQuestionsWithAnswer] = useState<any[]>([]);
  const [isShowingResult, setIsShowingResult] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState<any[]>([]);
  const [excludeDoneQuiz, setExcludeDoneQuiz] = useState(true);
  const questionsPositionsRefs = useRef<any[]>([]);

  const time = new Date();
  const [questionsFromLS, setQuestionsFromLS] = useLocalStorage(
    localStorageKey,
    JSON.stringify(
      localStorageKey === "baseQuestions"
        ? baseQuiz?.questions
        : sailQuiz?.questions
    )
  );
  const themes =
    localStorageKey === "baseQuestions" ? baseQuiz?.themes : sailQuiz?.themes;

  const correctAnswerButtonRef = useRef(null);
  time.setSeconds(time.getSeconds() + examTime); // n minutes timer

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: time,
    onExpire: () => console.warn("Timer expired"),
  });

  const [minNumOfCorrectAnswers, setMinNumOfCorrectAnswers] = useState(0);

  useEffect(() => {
    setQuestions([]);
    setQuestionsWithAnswer([]);
    setWereAnswersChecked(false);

    setMinNumOfCorrectAnswers(localStorageKey === "baseQuestions" ? 16 : 4);

    return () => {
      restart(time, false);
    };
  }, []);

  const setAnswer = useCallback(
    (questionIndex: number, answerIndex: number) => {
      const currentQuestionWithAnswer = {
        progressive: questions[questionIndex].progressive,
        answerGiven: {
          index: answerIndex,
          text: questions[questionIndex]?.answers[answerIndex].text,
        },
        isCorrect: questions[questionIndex]?.answers[answerIndex].isCorrect,
        currentQuizQuestionIndex: questionIndex,
        correctAnswer: {
          ...questions[questionIndex]?.answers.find(
            (ans: any) => ans.isCorrect
          ),
          index: questions[questionIndex]?.answers?.indexOf(
            questions[questionIndex]?.answers.find((ans: any) => ans.isCorrect)
          ),
        },
        text: questions[questionIndex].text,
      };
      const currentQuestionsCopy = [...questionsWithAnswer]?.filter(
        (q) => q.progressive !== currentQuestionWithAnswer.progressive
      );
      currentQuestionsCopy.push(currentQuestionWithAnswer);
      setQuestionsWithAnswer(currentQuestionsCopy);
      /* console.log("Questions", currentQuestionsCopy); */
    },
    [questions, questionsWithAnswer]
  );

  const [showResultModal, setShowResultModal] = useState(false);
  const [wereAnswersChecked, setWereAnswersChecked] = useState(false);
  const [showImageModal, setShowImageModal] = useState({
    show: false,
    url: "",
    progressive: "",
  });
  const [showExplanationModal, setShowExplanationModal] = useState({
    show: false,
    title: "",
    explanation: "",
    progressive: "",
  });

  const handleClose = () => setShowResultModal(false);

  const doCorrectAnswers = useCallback(() => {
    setCorrectAnswers(questionsWithAnswer?.filter((q) => q.isCorrect));
    try {
      // segna nel localStorage solo risposte con errori
      if (questionsFromLS) {
        const tempQuestionsFromLS = JSON.parse(questionsFromLS);

        questionsWithAnswer.forEach((question) => {
          if (tempQuestionsFromLS) {
            const questionFromLS = tempQuestionsFromLS.find(
              (lsQuestion: any) =>
                lsQuestion.progressive === question.progressive
            );
            if (!question.isCorrect) {
              questionFromLS.hasError = true;
            } else {
              questionFromLS.correctAtLeastOnce = true;
            }
            questionFromLS.answeredAtLeastOnce = true;
          }
        });
        setQuestionsFromLS(JSON.stringify(tempQuestionsFromLS));
      }
      setWereAnswersChecked(true);
    } catch (e) {
      console.error(e);
    }
  }, [questionsWithAnswer]);

  const [includedCategories, setIncludedCategories] = useState<string[]>(
    themes ?? []
  );

  useEffect(() => {
    console.log("includedCategories", includedCategories);
  }, [includedCategories]);

  useEffect(() => {
    if (isRunning) {
      questionsPositionsRefs.current[0].scrollIntoView({ behavior: "smooth" });
    }
  }, [isRunning]);

  const startNewSimulation = useCallback(() => {
    const cquestions = partial
      ? createExamSimulation(includedCategories)
      : createExamSimulation(excludeDoneQuiz, JSON.parse(questionsFromLS));
    /* Object.keys(groupBy(cquestions, "theme")).forEach((theme) =>
        console.log(
          theme,
          groupBy(cquestions, "theme")[theme].length
        )
      ); */
    setQuestions(cquestions);

    start();
    setQuestionsWithAnswer([]);
    setIsShowingResult(false);
    setWereAnswersChecked(false);
  }, [includedCategories, excludeDoneQuiz, questionsFromLS]);

  return (
    <Container>
      <>
        <div style={{ marginBottom: "2em" }}>
          {!isRunning && !wereAnswersChecked && (
            <>
              <Container style={{ marginBottom: "40px" }}>
                {partial && (
                  <Row style={{ marginBottom: "2em" }}>
                    <Col lg={4}></Col>
                    <Col xs={12} lg={8}>
                      <Form>
                        {themes.map((theme, index) => {
                          return (
                            <Form.Check
                              type="switch"
                              id={`custom-switch-${index}`}
                              label={theme}
                              key={theme}
                              name={index.toString()}
                              style={{ textAlign: "start" }}
                              defaultChecked
                              onChange={(e) => {
                                let currentIncludedCategories = [
                                  ...includedCategories,
                                ];
                                if (
                                  e.target.checked &&
                                  !currentIncludedCategories?.includes(theme)
                                ) {
                                  currentIncludedCategories.push(themes[index]);
                                  //setIncludedCategories()
                                } else if (
                                  !e.target.checked &&
                                  currentIncludedCategories?.includes(theme)
                                ) {
                                  currentIncludedCategories =
                                    currentIncludedCategories.filter(
                                      (item) => item !== theme
                                    );
                                }

                                setIncludedCategories(
                                  currentIncludedCategories
                                );
                              }}
                            />
                          );
                        })}
                      </Form>
                    </Col>
                    <Col></Col>
                  </Row>
                )}
                <Button variant="primary" onClick={startNewSimulation}>
                  Avvia
                </Button>
              </Container>
              {!partial && (
                <Row>
                  <Col>
                    <Form style={{ display: "inline-block" }}>
                      <Form.Check
                        type="switch"
                        id={`excludeDoneQuiz`}
                        label={"Escludi quiz già svolti"}
                        key={"excludeDoneQuiz"}
                        name={"excludeDoneQuiz"}
                        style={{ textAlign: "start" }}
                        defaultChecked
                        onChange={(e) => setExcludeDoneQuiz(!excludeDoneQuiz)}
                      />
                    </Form>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
        <Container style={{ marginBottom: "120px" }}>
          {questions?.map((question, questionIndex) => {
            return (
              <>
                <div
                  style={{ height: "80px" }}
                  ref={(element) => {
                    questionsPositionsRefs.current[questionIndex] = element;
                  }}
                ></div>
                <div
                  key={`q-${questionIndex}`}
                  id={`q-${questionIndex}-id`}
                  style={{ position: "relative" }}
                >
                  {/* {questionIndex > 0 && (
                    <Button
                      style={{
                        backgroundColor: "#999999",
                        border: "2px solid #999999",
                        borderRadius: "0",
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        color: "white",
                        fontSize: "0.6em",
                      }}
                      variant="light"
                      onClick={() =>
                        questionsPositionsRefs.current[
                          questionIndex - 1
                        ].scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Precedente
                    </Button>
                  )} */}

                  <QuizQuestion
                    question={question}
                    questionIndex={questionIndex}
                    numOfQuestions={questions.length}
                    questionsWithAnswer={questionsWithAnswer}
                    wereAnswersChecked={wereAnswersChecked}
                    setShowImageModal={setShowImageModal}
                    setShowExplanationModal={setShowExplanationModal}
                    setAnswer={setAnswer}
                    scrollFunction={() => {
                      if (questionIndex < questions.length - 1) {
                        questionsPositionsRefs.current[
                          questionIndex + 1
                        ].scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  />
                  {/* {questionIndex < questions.length - 1 && (
                    <Button
                      style={{
                        backgroundColor: "#999999",
                        border: "2px solid #999999",
                        borderRadius: "0",
                        borderBottomRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        color: "white",
                        fontSize: "0.6em",
                      }}
                      variant="light"
                      onClick={() =>
                        questionsPositionsRefs.current[
                          questionIndex + 1
                        ].scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Prossima
                    </Button>
                  )} */}
                </div>
              </>
            );
          })}
        </Container>

        {questionsWithAnswer && questionsWithAnswer.length > 0 && (
          <Modal show={showResultModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Risultati</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {correctAnswers.length >= minNumOfCorrectAnswers ? (
                <div style={{ textAlign: "center" }}>
                  <h5>
                    <b>{correctAnswers.length}</b> risposte corrette su{" "}
                    <b>{numOfQuestions}</b>
                  </h5>
                  <img src="/popeye-happy.png" alt="ok" height="200px" />
                  <p>Esame superato! Continua così!</p>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <h5>
                    <b>{correctAnswers.length}</b> risposte corrette su{" "}
                    <b>{numOfQuestions}</b>
                  </h5>
                  <img src="/popeye-sad.png" alt="ok" height="200px" />
                  <p>
                    Hai ancora tanto da imparare, continua ad esercitarti e{" "}
                    <b>vedrai i miglioramenti!</b>
                  </p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Chiudi
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={showImageModal.show}
          onHide={() =>
            setShowImageModal({ show: false, url: "", progressive: "" })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Immagine quesito {showImageModal.progressive}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-sm-center">
              <img
                src={showImageModal.url}
                alt="pic"
                style={{ maxWidth: "100%" }}
              ></img>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          show={showExplanationModal.show}
          onHide={() =>
            setShowExplanationModal({
              show: false,
              title: "",
              explanation: "",
              progressive: "",
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>{showExplanationModal.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-sm-center">
              <p>{showExplanationModal.explanation}</p>
              <i>Quesito {showExplanationModal.progressive}</i>
            </Row>
          </Modal.Body>
        </Modal>
      </>
      <ScrollToTop smooth style={{ marginBottom: "50px" }} />

      {(isRunning || wereAnswersChecked) && (
        <QuizNavBar
          children={
            <>
              {isRunning && (
                <Row style={{ width: "100%" }}>
                  <Col xs={5}>
                    <Row>
                      <div>
                        <Button
                          variant="danger"
                          onClick={() => {
                            setQuestions([]);
                            restart(time, false);
                            setQuestionsWithAnswer([]);
                            setWereAnswersChecked(false);
                            setIncludedCategories(themes);
                          }}
                          style={{ marginRight: "1em" }}
                        >
                          Stop
                        </Button>

                        <span
                          style={{ fontWeight: isRunning ? "bold" : "normal" }}
                        >
                          {minutes}:
                          {!!seconds.toLocaleString()[1]
                            ? seconds
                            : `0${seconds}`}
                        </span>
                      </div>
                    </Row>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <Button
                      variant="success"
                      onClick={() => {
                        restart(time, false);
                        doCorrectAnswers();
                        setIsShowingResult(true);
                        setShowResultModal(true);
                      }}
                      disabled={questionsWithAnswer.length < numOfQuestions}
                      ref={correctAnswerButtonRef}
                    >
                      {questionsWithAnswer.length < numOfQuestions
                        ? `${questionsWithAnswer.length} su ${numOfQuestions}`
                        : "Correggi"}
                    </Button>
                  </Col>
                </Row>
              )}
              {!isRunning && wereAnswersChecked && (
                <>
                  <Button variant="primary" onClick={startNewSimulation}>
                    Avvia
                  </Button>
                  {!partial && (
                    <Row>
                      <Col>
                        <Form style={{ display: "inline-block" }}>
                          <Form.Check
                            type="switch"
                            id={`excludeDoneQuiz`}
                            label={"Escludi quiz già svolti"}
                            key={"excludeDoneQuiz"}
                            name={"excludeDoneQuiz"}
                            style={{ textAlign: "start" }}
                            defaultChecked
                            onChange={(e) =>
                              setExcludeDoneQuiz(!excludeDoneQuiz)
                            }
                          />
                        </Form>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          }
        />
      )}
    </Container>
  );
};

export default SimulationVertical;
