import React from "react";
import Container from "react-bootstrap/Container";
import { BASE_EXAM_TIME, NUM_OF_BASE_EXAM_QUESTIONS } from "../../constants";
import useQuiz from "../../hooks/useQuiz";
import SimulationVertical from "../../components/simulationVertical";

const BaseSimulationPartial: React.FC = () => {
  const { createBaseExamLimitedSimulation } = useQuiz();

  React.useEffect(() => {
    document.title = "Nautiquiz - Simulazione Motore per categorie";
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <h1>Esame parziale</h1>
      <p>
        L'esame sarà composto da <b>{NUM_OF_BASE_EXAM_QUESTIONS} domande</b>,
        ognuna con <b>3 possibili risposte</b>, di cui una è corretta.
      </p>
      <p>
        Il tempo a tua disposizione è di <b>{BASE_EXAM_TIME / 60} minuti</b>.
      </p>
      <p>Puoi scegliere un insieme ristretto di argomenti.</p>
      <SimulationVertical
        partial
        numOfQuestions={NUM_OF_BASE_EXAM_QUESTIONS}
        examTime={BASE_EXAM_TIME}
        createExamSimulation={createBaseExamLimitedSimulation}
        localStorageKey="baseQuestions"
      />
    </Container>
  );
};

export default BaseSimulationPartial;
