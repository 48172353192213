import baseQuiz from "../quiz/base-quiz.json";
import sailQuiz from "../quiz/sail-quiz.json";
import { groupBy, sampleSize, shuffle } from "lodash";
import {
  baseThemesNumberOfQuestionsMap,
  NUM_OF_BASE_EXAM_QUESTIONS,
  NUM_OF_SAIL_EXAM_QUESTIONS,
} from "../constants";

const useQuiz = () => {
  const createBaseExamSimulation = (
    excludeDoneQuiz = true,
    quizFromLS: any[] = []
  ) => {
    const categories = baseQuiz?.themes;

    const tempQuestions: any[] = [];

    const questionsToExclude = quizFromLS
      .filter((q) => q.correctAtLeastOnce)
      .map((q) => q.progressive);
    console.log("To exclude: ", questionsToExclude);

    categories.forEach((category: string) => {
      if (Object.keys(baseThemesNumberOfQuestionsMap).includes(category)) {
        const numOfQuestionsToGet = baseThemesNumberOfQuestionsMap[category];
        let questionsToConsider = baseQuiz.questions;
        if (excludeDoneQuiz) {
          questionsToConsider = questionsToConsider.filter(
            (q) => !questionsToExclude.includes(q.progressive)
          );
        }
        const questions = sampleSize(
          questionsToConsider.filter((item) => item.theme === category),
          numOfQuestionsToGet
        );
        tempQuestions.push(...questions);
      }
    });

    const shuffledQuestions = shuffle(tempQuestions);

    if (!(shuffledQuestions?.length === NUM_OF_BASE_EXAM_QUESTIONS)) {
      throw Error("Exam questions are not enough or are too much");
    }
    Object.keys(groupBy(shuffledQuestions, "theme")).forEach((theme) =>
      console.log(theme, groupBy(shuffledQuestions, "theme")[theme].length)
    );
    return shuffledQuestions;
  };

  const createBaseExamLimitedSimulation = (includedCategories: string[]) => {
    console.log("Included Categories are", includedCategories);
    const questions = shuffle(
      sampleSize(
        baseQuiz?.questions?.filter((question) =>
          includedCategories?.includes(question.theme)
        ),
        NUM_OF_BASE_EXAM_QUESTIONS
      )
    );
    Object.keys(groupBy(questions, "theme")).forEach((theme) =>
      console.log(theme, groupBy(questions, "theme")[theme].length)
    );
    return questions;
  };

  const createExamErrorSimulation = (
    quizFromLS: any[],
    selectedTheme: string
  ) => {
    let quizToConsider = JSON.parse(JSON.stringify(quizFromLS));
    if (selectedTheme && selectedTheme !== "all") {
      quizToConsider = quizToConsider.filter(
        (q: any) => q.theme === selectedTheme
      );
    }
    return quizToConsider.filter((q: any) => q.hasError);
  };

  const createExamTrainingSimulation = (
    quizFromLS: any[],
    selectedTheme: string
  ) => {
    let quizToConsider = JSON.parse(JSON.stringify(quizFromLS));
    if (selectedTheme && selectedTheme !== "all") {
      quizToConsider = quizToConsider.filter(
        (q: any) => q.theme === selectedTheme
      );
    }
    return quizToConsider.filter((q: any) => !q.correctAtLeastOnce);
  };

  const createSailExamSimulation = (
    excludeDoneQuiz = true,
    quizFromLS: any[] = []
  ) => {
    const questionsToExclude = quizFromLS
      .filter((q) => q.correctAtLeastOnce)
      .map((q) => q.progressive);
    let questionsToConsider = sailQuiz.questions;
    if (excludeDoneQuiz) {
      questionsToConsider = questionsToConsider.filter(
        (q) => !questionsToExclude.includes(q.progressive)
      );
    }
    /* console.log("To exclude: ", questionsToExclude); */
    return shuffle(sampleSize(questionsToConsider, NUM_OF_SAIL_EXAM_QUESTIONS));
  };

  const createSailExamLimitedSimulation = (includedCategories: string[]) => {
    const questions = shuffle(
      sampleSize(
        sailQuiz?.questions?.filter((question) =>
          includedCategories?.includes(question.theme)
        ),
        NUM_OF_SAIL_EXAM_QUESTIONS
      )
    );
    Object.keys(groupBy(questions, "theme")).forEach((theme) =>
      console.log(theme, groupBy(questions, "theme")[theme].length)
    );
    return questions;
  };

  return {
    baseQuiz,
    sailQuiz,
    createBaseExamSimulation,
    createBaseExamLimitedSimulation,
    createSailExamSimulation,
    createSailExamLimitedSimulation,
    createExamErrorSimulation,
    createExamTrainingSimulation,
  };
};

export default useQuiz;
