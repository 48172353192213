import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const Sail: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container style={{ marginTop: "80px", maxWidth: "800px" }}>
      <>
        <Button
          variant="outline-primary"
          onClick={() => navigate("/sail/simulation")}
          style={{ marginRight: "1em" }}
        >
          Esame vela completo
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => navigate("/sail/simulation-partial")}
          style={{ marginRight: "1em" }}
        >
          Esame vela parziale
        </Button>
      </>
    </Container>
  );
};

export default Sail;
