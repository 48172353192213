import React from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export interface CardLink {
  url: string;
  linkDescription: string;
  disabled?: boolean;
  variant?: string;
}

interface IProps {
  title: string;
  description: React.ReactNode;
  linkList: CardLink[];
}

const SelectQuizCard: React.FC<IProps> = ({ title, description, linkList }) => {
  const navigate = useNavigate();

  return (
    <Container style={{ marginBottom: "20px" }}>
      <Card>
        <Card.Header as="h5">{title}</Card.Header>
        <Card.Body>
          {description}
          <Row className="justify-content-md-center">
            {linkList.map((link, index) => (
              <Col
                key={`quiz-card-${index}-el`}
                xs={12}
                sm={3}
                style={{ marginBottom: "1em" }}
              >
                <Button
                  key={link.url}
                  variant={link.variant}
                  onClick={() => navigate(link.url)}
                  style={{ minWidth: "100px" }}
                  disabled={link.disabled}
                >
                  {link.linkDescription}
                </Button>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SelectQuizCard;
