import React from "react";

interface IProps {
  url: string;
  handleClick: () => void;
}

const QuizImage: React.FC<IProps> = ({ url, handleClick }) => {
  return (
    <>
      <div onClick={handleClick} className="quiz-img-container clickable">
        <img src={url} alt="pic" style={{ maxWidth: "100%" }}></img>
      </div>
    </>
  );
};

export default QuizImage;
