import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import deviationTable from "../../quiz/deviation-table.json";

const DeviationTable: React.FC = () => {
  const northToSouthRows = deviationTable.northToSouth.map((item, index) => (
    <tr key={`north-${index}`}>
      <td>{item.pm}</td>
      <td>{item.deviation}</td>
      <td>{item.pb}</td>
    </tr>
  ));

  const southToNorthRows = deviationTable.southToNorth.map((item, index) => (
    <tr key={`south-${index}`}>
      <td>{item.pm}</td>
      <td>{item.deviation}</td>
      <td>{item.pb}</td>
    </tr>
  ));

  return (
    <Container style={{ marginTop: "80px" }}>
      <Container style={{ maxWidth: "800px" }}>
        <Row>
          <Col>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Pm</th>
                  <th>Deviazione</th>
                  <th>Pb</th>
                </tr>
              </thead>
              <tbody>{northToSouthRows}</tbody>
            </Table>
          </Col>
          <Col>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Pm</th>
                  <th>Deviazione</th>
                  <th>Pb</th>
                </tr>
              </thead>
              <tbody>{southToNorthRows}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DeviationTable;
