import "./App.css";
import Header from "./components/header";
import Home from "./pages/home";
import Base from "./pages/base";
import Sail from "./pages/sail";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import baseQuiz from "./quiz/base-quiz.json";
import sailQuiz from "./quiz/sail-quiz.json";
import BaseSimulation from "./pages/base/simulation";
import SailSimulation from "./pages/sail/simulation";
import BaseSimulationPartial from "./pages/base/simulation-partial";
import SailSimulationPartial from "./pages/sail/simulation-partial";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { isEmpty } from "lodash";
import BaseTraining from "./pages/base/training";
import SailTraining from "./pages/sail/training";
import BaseErrors from "./pages/base/errors";
import SailErrors from "./pages/sail/errors";
import { firebaseConfig } from "./utils/firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { StatsPage } from "./pages/stats";
import ChartsSimulation, {
  SimSectorsEnum,
} from "./pages/charts/chartsSimulation";
import ChartsQuiz, { SectorsEnum } from "./pages/charts/chartsQuiz";
import ChartsQuizList from "./pages/charts/chartsQuizList";
import { Breadcrumbs } from "./components/breadcrumbs/breadcrumbs";
import chartQuiz from "./quiz/chart-quiz.json";
import chartSimulation5d from "./quiz/chart-simulation-5d.json";
import chartSimulation42d from "./quiz/chart-simulation-42d.json";
import DeviationTable from "./pages/charts/deviationTable";
import { DonatePage } from "./pages/donate";

function App() {
  const [baseQuestionsFromLS, setBaseQuestionsFromLS] = useLocalStorage(
    "baseQuestions",
    JSON.stringify(baseQuiz?.questions)
  );
  const [sailQuestionsFromLS, setSailQuestionsFromLS] = useLocalStorage(
    "sailQuestions",
    JSON.stringify(sailQuiz?.questions)
  );

  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);

  useEffect(() => {
    if (isEmpty(baseQuestionsFromLS)) {
      setBaseQuestionsFromLS(JSON.stringify(baseQuiz?.questions));
    }
    if (isEmpty(sailQuestionsFromLS)) {
      setSailQuestionsFromLS(JSON.stringify(sailQuiz?.questions));
    }
  }, []);

  return (
    <div className="App">
      <div style={{ minHeight: "calc(100vh - 140px)" }}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Home />} />
            </Route>
            <Route path="/home" element={<Navigate to="/" replace />} />
            <Route path="/base" element={<Base />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/base/training" element={<BaseTraining />} />
            <Route path="/base/simulation" element={<BaseSimulation />} />
            <Route path="/base/errors" element={<BaseErrors />} />
            <Route
              path="/base/simulation-partial"
              element={<BaseSimulationPartial />}
            />
            <Route path="/sail" element={<Sail />} />
            <Route path="/sail/training" element={<SailTraining />} />
            <Route path="/sail/simulation" element={<SailSimulation />} />
            <Route path="/sail/errors" element={<SailErrors />} />
            <Route
              path="/sail/simulation-partial"
              element={<SailSimulationPartial />}
            />
            <Route path="/charts/quiz" element={<ChartsQuiz />} />
            <Route
              path="/charts/quiz/sector_1"
              element={
                <ChartsQuizList
                  quiz={chartQuiz}
                  title={SectorsEnum.NW_H}
                  filterFieldValue={SectorsEnum.NW_H}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[{ name: "Quiz Carteggio", url: "/charts/quiz" }]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/quiz/sector_2"
              element={
                <ChartsQuizList
                  quiz={chartQuiz}
                  title={SectorsEnum.NW_V}
                  filterFieldValue={SectorsEnum.NW_V}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[{ name: "Quiz Carteggio", url: "/charts/quiz" }]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/quiz/sector_3"
              element={
                <ChartsQuizList
                  quiz={chartQuiz}
                  title={SectorsEnum.SE}
                  filterFieldValue={SectorsEnum.SE}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[{ name: "Quiz Carteggio", url: "/charts/quiz" }]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-5d"
              element={<ChartsSimulation chartCode="5d" />}
            />
            <Route
              path="/charts/simulation-42d"
              element={<ChartsSimulation chartCode="42d" />}
            />
            <Route
              path="/charts/simulation-5d/sector_A"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title={SimSectorsEnum.A}
                  filterFieldValue={SimSectorsEnum.A}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/correnti"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title="Argomento: Correnti"
                  filterFieldValue="correnti"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/carburante"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title="Argomento: Carburante"
                  filterFieldValue="carburante"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/navigazione_costiera"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title="Argomento: Navigazione Costiera"
                  filterFieldValue="navigazione costiera"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/scarroccio"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title="Argomento: Scarroccio"
                  filterFieldValue="scarroccio"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/correnti"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title="Argomento: Correnti"
                  filterFieldValue="correnti"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/carburante"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title="Argomento: Carburante"
                  filterFieldValue="carburante"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/navigazione_costiera"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title="Argomento: Navigazione Costiera"
                  filterFieldValue="navigazione costiera"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/scarroccio"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title="Argomento: Scarroccio"
                  filterFieldValue="scarroccio"
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="theme"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/sector_B"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title={SimSectorsEnum.B}
                  filterFieldValue={SimSectorsEnum.B}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/sector_C"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title={SimSectorsEnum.C}
                  filterFieldValue={SimSectorsEnum.C}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-5d/sector_D"
              element={
                <ChartsQuizList
                  quiz={chartSimulation5d}
                  title={SimSectorsEnum.D}
                  filterFieldValue={SimSectorsEnum.D}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 5D",
                          url: "/charts/simulation-5d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/sector_A"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title={SimSectorsEnum.A}
                  filterFieldValue={SimSectorsEnum.A}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/sector_B"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title={SimSectorsEnum.B}
                  filterFieldValue={SimSectorsEnum.B}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/sector_C"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title={SimSectorsEnum.C}
                  filterFieldValue={SimSectorsEnum.C}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/simulation-42d/sector_D"
              element={
                <ChartsQuizList
                  quiz={chartSimulation42d}
                  title={SimSectorsEnum.D}
                  filterFieldValue={SimSectorsEnum.D}
                  breadcrumbs={
                    <Breadcrumbs
                      path={[
                        {
                          name: "Prova di Carteggio - 42D",
                          url: "/charts/simulation-42d",
                        },
                      ]}
                    />
                  }
                  filterFieldName="sector"
                />
              }
            />
            <Route
              path="/charts/deviation-table"
              element={<DeviationTable />}
            />
            <Route path="/donate" element={<DonatePage />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.8em",
          /* position: "fixed",
          bottom: 0, */
          width: "100%",
          background: "#f0f0f0",
          padding: "1em",
        }}
      >
        <span>&copy; 2023, GGdev</span>
        <br />
        <span>
          I quiz qui presenti provengono dall'
          <a
            target="_blank"
            href="https://mit.gov.it/nfsmitgov/files/media/normativa/2022-05/ALLEGATO%20A%20QUIZ%20PATENTI%20NAUTICHE%20DD%20131%20DEL%2031%20MAGGIO%202022.pdf"
            rel="noreferrer"
          >
            Allegato A del 31/05/2022
          </a>{" "}
          del{" "}
          <a
            target="_blank"
            href="https://mit.gov.it/normativa/decreto-dirigenziale-numero-131-del-31052022"
            rel="noreferrer"
          >
            Decreto Dirigenziale numero 131 del 31/05/2022
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
