import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import chartQuiz5D from "../../quiz/chart-simulation-5d.json";
import chartQuiz42D from "../../quiz/chart-simulation-42d.json";

interface IProps {
  chartCode: string;
}

export enum SimSectorsEnum {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

const ChartsSimulation: React.FC<IProps> = ({ chartCode }) => {
  const navigate = useNavigate();
  const [chartQuiz, setChartQuiz] = useState(chartQuiz5D);

  const getSectorKey = (sector: string): string => {
    return "/charts/simulation-" + chartCode + "/sector_" + sector;
  };

  const getThemeKey = (theme: string): string => {
    return "/charts/simulation-" + chartCode + "/" + theme.replaceAll(" ", "_");
  };

  useEffect(() => {
    if (chartCode === "42d") {
      setChartQuiz(chartQuiz42D);
    } else {
      setChartQuiz(chartQuiz5D);
    }
  }, [chartCode]);

  return (
    <Container style={{ marginTop: "80px" }}>
      <h1>Prova di Carteggio - {chartCode.toLocaleUpperCase()}</h1>
      <Tabs
        defaultActiveKey="themes"
        id="uncontrolled-tab-example"
        className="mb-3"
        style={{ justifyContent: "center" }}
      >
        <Tab eventKey="themes" title="Per Argomenti">
          <Row>
            {chartQuiz.themes.map((theme, i) => {
              return (
                <Col
                  key={i}
                  xs={12}
                  md={6}
                  /* onClick={() => setDoQuiz({ theme, do: true })} */
                >
                  <Card
                    style={{ marginBottom: "1em" }}
                    className="clickable-card"
                    onClick={() => navigate(getThemeKey(theme))}
                  >
                    <Card.Body>
                      <h5>{theme[0].toUpperCase() + theme.slice(1)}</h5>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Tab>
        <Tab eventKey="sectors" title="Per Settori">
          <Row>
            {chartQuiz.sectors.map((sector, i) => {
              return (
                <Col
                  key={i}
                  xs={12}
                  md={6}
                  /* onClick={() => setDoQuiz({ theme, do: true })} */
                >
                  <Card
                    style={{ marginBottom: "1em" }}
                    className="clickable-card"
                    onClick={() => navigate(getSectorKey(sector))}
                  >
                    <Card.Body>
                      <h5>{sector}</h5>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Tab>
      </Tabs>
      <Container style={{ maxWidth: "800px" }}></Container>
    </Container>
  );
};

export default ChartsSimulation;
