import React from "react";
import useQuiz from "../../hooks/useQuiz";
import { useLocalStorage } from "usehooks-ts";
import Training from "../../components/training/training";

export type TrainingQuestionsWithAnswersKey =
  | "trainingBaseQuestions"
  | "trainingSailQuestions"
  | "errorsQuestions";

const BaseTraining: React.FC = () => {
  const { baseQuiz } = useQuiz();
  const [baseQuestionsFromLS] = useLocalStorage(
    "baseQuestions",
    JSON.stringify(baseQuiz?.questions)
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Training
      questionsFromLS={baseQuestionsFromLS}
      themes={baseQuiz.themes}
      questionsFromLSWithAnswersKey={"trainingBaseQuestions"}
    />
  );
};

export default BaseTraining;
