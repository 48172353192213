import React from "react";
import useQuiz from "../../hooks/useQuiz";
import { useLocalStorage } from "usehooks-ts";
import Training from "../../components/training/training";

const SailTraining: React.FC = () => {
  const { sailQuiz } = useQuiz();
  const [sailQuestionsFromLS] = useLocalStorage(
    "sailQuestions",
    JSON.stringify(sailQuiz?.questions)
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Training
      questionsFromLS={sailQuestionsFromLS}
      themes={sailQuiz.themes}
      questionsFromLSWithAnswersKey={"trainingSailQuestions"}
    />
  );
};

export default SailTraining;
